// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import React, { useRef } from 'react';
import { CustomAppLayout, Navigation } from './pages/commons/common-components';
import { TableSelectFilter } from './pages/table-select-filter';
import './styles/base.scss';
import { info } from './data/info';
import Cards from '@cloudscape-design/components/cards';
import ReactPlayer from 'react-player';
import Flashbar from '@cloudscape-design/components/flashbar';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SpaceBetween } from '@cloudscape-design/components';

try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: 'arn:aws:iam::495538033277:role/RUM-Monitor-ap-southeast-2-495538033277-0430747151171-Unauth',
    identityPoolId: 'ap-southeast-2:6a090fad-db1c-4c33-ab38-0ad356ec0ac7',
    endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    enableXRay: false,
  };

  const APPLICATION_ID = 'f2237a43-2401-47d1-8e4d-5bca83fd25ea';
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'ap-southeast-2';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
const Main = () => {
  const appLayout = useRef();

  const date = new Date(info.last_updated * 1000);

  const [items, setItems] = React.useState([
    {
      type: 'info',
      dismissible: true,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setItems([]),
      content: (
        <>
          ROSA now supports Graviton instances. Learn more:&nbsp;
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/g5g/"
            target="_blank"
            rel="noreferrer"
          >
            G5g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/t4/"
            target="_blank"
            rel="noreferrer"
          >
            T4g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/c6g/"
            target="_blank"
            rel="noreferrer"
          >
            C6g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/c7g/"
            target="_blank"
            rel="noreferrer"
          >
            C7g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/hpc7g/"
            target="_blank"
            rel="noreferrer"
          >
            Hpc7g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/m6g/"
            target="_blank"
            rel="noreferrer"
          >
            M6g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/m7g/"
            target="_blank"
            rel="noreferrer"
          >
            M7g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/r6g/"
            target="_blank"
            rel="noreferrer"
          >
            R6g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/r7g/"
            target="_blank"
            rel="noreferrer"
          >
            R7g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/i4g/"
            target="_blank"
            rel="noreferrer"
          >
            I4g
          </a>
          <br />
          <br />
          Note: Graviton is available for <strong>ROSA HCP (Hosted Control Planes) clusters only</strong>. Watch{' '}
          <a
            style={{ color: '#FFF' }}
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=qNRZhc4SSPs"
          >
            {' '}
            Migrating from ROSA classic to HCP
          </a>{' '}
          if you are currently using ROSA classic.
        </>
      ),
      id: 'message_1',
    },
  ]);

  return (
    <>
      <CustomAppLayout
        ref={appLayout}
        navigation={<Navigation activeHref="#/instances" />}
        content={
          <>
            <SpaceBetween direction="vertical" size="xs">
              <Flashbar items={items} />
              <TableSelectFilter
                loadHelpPanelContent={() => {
                  appLayout.current?.focusToolsClose();
                }}
                height={50}
              />
              <small style={{ float: 'right', margin: '0 20px' }}>&nbsp;</small>
              <Cards
                style={{ margin: '20px' }}
                ariaLabels={{
                  itemSelectionLabel: (e, i) => `select ${i.name}`,
                }}
                cardDefinition={{
                  header: item => item.name,
                  sections: [
                    {
                      id: 'description',
                      content: item => item.description,
                    },
                  ],
                }}
                cardsPerRow={[{ cards: 1 }, { minWidth: 300, cards: 3 }]}
                items={[
                  {
                    name: 'Cost Savings in ROSA',
                    alt: 'First',
                    description: (
                      <ReactPlayer
                        controls={true}
                        url="https://www.youtube.com/watch?v=QkvHYXgvSwo"
                        width={425}
                        height={175}
                      />
                    ),
                  },
                  {
                    name: 'Apply for a Proof-of-Concept environment',
                    alt: 'Second',
                    description: (
                      <ReactPlayer
                        controls={true}
                        url="https://www.youtube.com/watch?v=4-bUVwN8xX8"
                        width={425}
                        height={175}
                      />
                    ),
                  },
                  {
                    name: 'ROSA Private Offers',
                    alt: 'Third',
                    description: (
                      <ReactPlayer
                        controls={true}
                        url="https://www.youtube.com/watch?v=K0OGlpYMZEo"
                        width={425}
                        height={175}
                      />
                    ),
                  },
                  {
                    name: 'VMware to ROSA Virtualization',
                    alt: 'Third',
                    description: (
                      <ReactPlayer
                        controls={true}
                        url="https://www.youtube.com/watch?v=DKjucYGg8dI"
                        width={425}
                        height={175}
                      />
                    ),
                  },
                  {
                    name: 'OpenShift Migrations permutations',
                    alt: 'Third',
                    description: (
                      <ReactPlayer
                        controls={true}
                        url="https://youtu.be/g7cUJyTDIac?si=bXOG8-_EhsTRgYkG"
                        width={425}
                        height={175}
                      />
                    ),
                  },

                  {
                    name: 'From ROSA Classic to HCP',
                    alt: 'Third',
                    description: (
                      <ReactPlayer
                        controls={true}
                        url="https://youtu.be/qNRZhc4SSPs?si=OeWLWtS8nhn83Eai"
                        width={425}
                        height={175}
                      />
                    ),
                  },
                ]}
              />
              <small style={{ float: 'right', margin: '0 20px' }}>
                Prices updated on {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()} (at {date.getHours()}:
                {date.getMinutes()})
              </small>
            </SpaceBetween>
          </>
        }
        contentType="table"
      />
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
